@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700,900');

@import "vendor/bootstrap-4.0.0-beta/bootstrap.scss";

.btn-primary {
  color: $white !important;
}

div.container5 {
  display: flex;
  align-items: center }


.font-weight-light {
  font-weight: $font-weight-light;
}
.font-weight-bolder {
  font-weight: $font-weight-bolder;
}

.bg-gray {
  background-color: $gray-500 !important;
}
.bg-black {
  background-color: $gray-700 !important;
}

.align-bullets li {
  list-style-position: inside;
}

.col-centered{
  float: none;
  margin: 0 auto;
}

.img-shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
}

.text-gray {
  color: $gray-500 !important;
}
.text-spaced {
  letter-spacing: 5px;
}

.star-bullets {
  list-style: none;
}
.star-bullets li:before {
  color: #6caae4;
  margin: 0 5px 0 -20px;
  font-family: 'FontAwesome';
  content: '\f005';
}

.border-overlap {
  position: absolute;
  right: 50%;
  bottom: -38px;
  left: 50%;
  transform:translate(-50%, -50%);
  width: 150px;
}

#most-popular {
  position:absolute;
  width: 100%;
  top: -54px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

h3.text-primary, h4.text-primary {
  color: #5F8FBB !important;
}

.docs-section {
  margin-top: 10px;
}
.docs-section .page-header {
  margin-bottom: 75px;
  border-bottom: 1px solid #ccc;
}
